<template>
    <div class="modal-profile-item-selector edit-profile-detail items-start bg-main">
        <div class="back-button-wrapper" @click="onClickClose">
            <i class="back-button material-icons">{{ 'chevron_left' }} </i> 
            <!-- <i class="back-button material-icons">{{ options.icon || 'chevron_left' }} </i> -->
            <span v-if="headerTitle" class="f-17 f-bold m-l-12 c-black">{{ headerTitle }}</span>
        </div>
        <ul class="result flex-fill">
            <li
                @click="onClickItem(item)"
                class="item flex-row items-center flex-between p-20"
                :class="{ selected: item.$$selected }"
                :key="item.id"
                v-for="item in stories"
            > 
            {{ item.name }}
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'StorySelector',
    props: ['profile', 'headerTitle', 'stories', 'userPersonalStory'],
    data: () => ({
        showSearch: true,
        buttonDisabled: false,
        selectedValue: '',
    }),
    watch: {
        selectedValue() {
            // if (this.options.userPersonalStory) {
            //     this.selectedValue = this.options.stories.find(o => o.id === this.options.userPersonalStory.personal_story_id)
            //     this.selectedValue.$$selected = false
            //     this.$emit('disabled', false)
            // }

            if (this.selectedValue) {
                this.selectedValue.$$selected = true
                this.$emit('disabled', false)
            } else {
                this.selectedValue.$$selected = false
                this.$emit('disabled', true)
            }
        },
    },
    mounted() {
        this.setSelectedValue()
    },
    computed: {
        essentialFunnel() {
            const fullPath = this.$route.fullPath.split('/')
            return fullPath.includes('signup')
        },
        section() {
            return this.$sections()
        },
        chooseOneMoreAllowed() {
            return false
        },
    },
    methods: {
        setSelectedValue() {
            if (this.userPersonalStory) {
                this.selectedValue = this.stories.find(o => o.id === this.userPersonalStory.personal_story_id)
                this.$set(this.selectedValue, '$$selected', true)
                this.$emit('disabled', false)
            }
        },
        
        async onClickItem(item) {
            this.$set(item, '$$selected', !item.$$selected)
            setTimeout(() => {
                if (!this.chooseOneMoreAllowed) {
                    this.selectedValue = item

                    this.stories.forEach(o => this.$set(o, '$$selected', false))
                }
            }, 200)

            const storyId = await this.$modal.custom({
                component: 'ModalStoryWriter',
                options: {
                    profile: this.profile,
                    storyId: item.id,
                    headerTitle: '퍼스널 스토리 작성하기',
                    storyTitle: item.name,
                    userPersonalStory: this.userPersonalStory,
                },
            })
            console.log(storyId)
            // this.selectedValue = ''
            if (this.selectedValue !== '' && this.selectedValue.id !== storyId) {
                this.$set(item, '$$selected', !item.$$selected)
                this.selectedValue = ''
            } 
            this.selectedValue = this.stories.find(o => o.id === storyId)
            this.$set(this.selectedValue, '$$selected', true)
            this.$emit('disabled', false)
            // this.$set(item, '$$selected', !item.$$selected)
            // this.selectedValue = ''

            // this.$emit('close')
        },
        
        onClickClose() {
            this.$stackRouter.pop()
            this.stories.forEach(o => this.$set(o, '$$selected', false))
        },
    },
}
</script>
<style scoped lang="scss">
.back-button-wrapper {
    position: relative;
    background: #FAF0E1;
    justify-content: center;

    i {
        position: absolute;
        width: 20px;
        height: 20px;
        left: 20px;
    }
}

ul {
    li {
        color: #7C7C7C;
        border-bottom: 0.1px solid #DCDCDC !important;
    }
}
</style>